.wpo-about-us-area {
	background: url(../../images/banner-old.png) no-repeat center top / cover;
	min-height: 250px;
	position: relative;
	display: flex;
	justify-content: center;
	flex-direction: column;
	z-index: 1;

	@media(max-width: 767px) {
		background: url(../../images/GSIBanner-Landscape-441x221.jpg) no-repeat center top / cover;
		max-width: 767px;
		min-height: 190px;
	}

	&:before {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		// background: rgba(13, 59, 93, 0.7);
		content: "";
		z-index: -1;
	}

	.wpo-breadcumb-wrap {
		text-align: center;

		h2 {
			font-size: 60px;
			color: #fff;
			line-height: 60px;
			margin-top: -10px;
			margin-bottom: 20px;
			margin-top: 10px;

			@media(max-width: 767px) {
				font-size: 30px;
				line-height: 35px;
				margin-bottom: 10px;
			}
		}

		ul {
			li {
				display: inline-block;
				padding: 0px 5px;
				color: #fff;
				position: relative;
				font-size: 20px;

				@media(max-width:767px) {
					font-size: 18px;
				}

				&:after {
					content: "/";
					position: relative;
					left: 7px;
				}

				&:last-child {
					span {
						color: #cbd4fd;
					}

					&:after {
						display: none;
					}
				}

				a {
					color: #fff;
					font-size: 20px;
					transition: all .3s;

					&:hover {
						color: $theme-primary-color;
					}
				}
			}
		}
	}
}



.wpo-section.wpo-section-s2 {
	padding-top: 170px;
}

.choose-us {
	list-style: disc !important;
}

.wpo-gallery-area {
	background: url(../../images/banner-old.png) no-repeat center center / cover;
	min-height: 250px;
	position: relative;
	display: flex;
	justify-content: center;
	flex-direction: column;
	z-index: 1;

	@media(max-width: 767px) {
		background: url(../../images/GSIBanner-Landscape-441x221.jpg) no-repeat center center / cover;
		min-height: 190px;
	}

	&:before {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		// background: rgba(13, 59, 93, 0.7);
		content: "";
		z-index: -1;
	}

	.wpo-breadcumb-wrap {
		text-align: center;

		h2 {
			font-size: 60px;
			color: #fff;
			line-height: 60px;
			margin-top: -10px;
			margin-bottom: 20px;
			margin-top: 10px;

			@media(max-width: 767px) {
				font-size: 30px;
				line-height: 35px;
				margin-bottom: 10px;
			}
		}

		ul {
			li {
				display: inline-block;
				padding: 0px 5px;
				color: #fff;
				position: relative;
				font-size: 20px;

				@media(max-width:767px) {
					font-size: 18px;
				}

				&:after {
					content: "/";
					position: relative;
					left: 7px;
				}

				&:last-child {
					span {
						color: #cbd4fd;
					}

					&:after {
						display: none;
					}
				}

				a {
					color: #fff;
					font-size: 20px;
					transition: all .3s;

					&:hover {
						color: $theme-primary-color;
					}
				}
			}
		}
	}
}

.wpo-events-area {
	background: url(../../images/banner-old.png) no-repeat center top / cover;
	min-height: 275px;
	position: relative;
	display: flex;
	justify-content: center;
	flex-direction: column;
	z-index: 1;

	@media(max-width: 767px) {
		background: url(../../images/GSIBanner-Landscape-441x221.jpg) no-repeat center top / cover;
		min-height: 190px;
	}

	&:before {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		// background: rgba(13, 59, 93, 0.7);
		content: "";
		z-index: -1;
	}

	.wpo-breadcumb-wrap {
		text-align: center;

		h2 {
			font-size: 60px;
			color: #fff;
			line-height: 60px;
			margin-top: -10px;
			margin-bottom: 20px;
			margin-top: 10px;

			@media(max-width: 767px) {
				font-size: 30px;
				line-height: 35px;
				margin-bottom: 10px;
			}
		}

		ul {
			li {
				display: inline-block;
				padding: 0px 5px;
				color: #fff;
				position: relative;
				font-size: 20px;

				@media(max-width:767px) {
					font-size: 18px;
				}

				&:after {
					content: "/";
					position: relative;
					left: 7px;
				}

				&:last-child {
					span {
						color: #cbd4fd;
					}

					&:after {
						display: none;
					}
				}

				a {
					color: #fff;
					font-size: 20px;
					transition: all .3s;

					&:hover {
						color: $theme-primary-color;
					}
				}
			}
		}
	}
}