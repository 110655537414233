.photo-folder-container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
}

.gallery-container {
    @media(max-width: 767px) {
        justify-content: center;
    }
}

.folder-container {

    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 0.16fr));
    gap: 20px;
    justify-content: center;
    width: 100%;

    /* Style for the individual items (Card) */
    .card {
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 100%;
        max-height: 400px;
        /* Adjust based on your preferred max height */
    }

    /* Ensure images and videos are contained and scaled correctly */
    .card-media img,
    .card-media video {
        width: 100%;
        /* Ensure it fills the container width */
        height: 200px;
        /* Set the fixed height for uniformity */
        object-fit: cover;
        /* Prevent stretching */
    }

    /* Optional: Adjust card title to stay within limits */
    .card-content {
        text-align: center;
        padding: 10px;
    }
}

.folderItem {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid #ccc;
    padding-bottom: 10px;
    padding-right: 0px !important;
    padding-left: 0px !important;
    margin: 10px;
    border-radius: 8px;
    width: 250px;
    height: 250px;
    cursor: pointer;
    justify-content: space-between;
}

.iconContainer {
    height: 100%;
    // max-width: 90%;
    font-size: 50px;
    color: #ffcc00;

    img {
        height: 100%;
        width: 250px;
        max-height: 200px;
    }
}

.folderIcon {
    font-size: 50px;
}

.folderName {
    margin-top: 10px;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
}

.imageContainer {
    // display: flex;
    // flex-wrap: wrap;
    // gap: 10px;
    // margin-top: 20px;
    // padding: 10px;
    // // justify-content: flex-start;
    // justify-content: center;

    .image {
        // height: 200px;
        // object-fit: cover;
        // border-radius: 8px;
    }
}

.backButton {
    display: flex;
    align-items: center;
    cursor: pointer;
    font-size: 16px;
    color: #007bff;
    margin-bottom: 20px;
}

.icon {
    margin-right: 8px;
}

.backText {
    font-weight: bold
}