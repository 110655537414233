/* 1.1	Theme Reset Style */
html {
    font-size: $base-font-size + px;
}

:root {
    scroll-behavior: unset;
}

body {
    font-family: $base-font;
    color: $body-color;
    background-color: $body-bg-color;
    font-size: 15px;
    font-size: calc-rem-value(15);
    overflow-x: hidden;

    @include media-query(767px) {
        font-size: 14px;
    }
}

p {
    font-size: 16px;
    color: $body-color;
    line-height: 1.8em;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    color: $heading-color;
    font-family: $heading-font !important;
    font-weight: 600;
}

ul {
    padding-left: 0;
    margin: 0;
    list-style: none;
}

a {
    text-decoration: none;
    @include transition-time(0.2s);
}

a:hover {
    text-decoration: none;
}

// Base SCSS
// font-size
@for $i from 1 through 100 {
    .f-s-#{$i} {
        font-size: $i+px !important;
    }
}

@for $x from -5 through 200 {

    // line-height
    .lh-#{$x} {
        line-height: $x+px;
    }

    // margin-top
    .mt-#{$x} {
        margin-top: $x+px !important;
    }

    // margin-bottom
    .mb-#{$x} {
        margin-bottom: $x+px !important;
    }

    // margin-right
    .mr-#{$x} {
        margin-right: $x+px;
    }

    // margin-left
    .ml-#{$x} {
        margin-left: $x+px;
    }

    // border-radius
    .br-#{$x} {
        border-radius: $x+px;
    }

    // Padding
    .pd-#{$x} {
        padding: $x+px;
    }

    // Padding right
    .pr-#{$x} {
        padding-right: $x+px !important;
    }

    // Padding left
    .pl-#{$x} {
        padding-left: $x+px !important;
    }

    // Padding top
    .pt-#{$x} {
        padding-top: $x+px;
    }

    // Padding bottom
    .pb-#{$x} {
        padding-bottom: $x+px;
    }

    // Gap between
    .gap-#{$x} {
        gap: $x+px;
    }

    // bottom
    .bt-#{$x} {
        bottom: $x+px;
    }

    // top
    .tp-#{$x} {
        top: $x+px
    }

    // right
    .rt-#{$x} {
        right: $x+px;
    }

    // left
    .lt-#{$x} {
        left: $x+px;
    }
}


@for $hw from 0 through 1650 {
    .height-#{$hw} {
        height: $hw+px;
    }

    .width-#{$hw} {
        width: $hw+px !important;
    }

    .min-width-#{$hw} {
        max-width: $hw+px;
    }

    .max-width-#{$hw} {
        max-width: $hw+px;
    }

    .min-height-#{$hw} {
        min-height: $hw+px;
    }

    .max-height-#{$hw} {
        max-height: $hw+px;
    }
}


// Padding top-bottom left-right
@for $p from 0 through 100 {
    @for $d from 0 through 10 {
        .p-tb-lr-#{$p}-#{d} {
            padding: $p+px $d+px;
        }
    }
}


// position
// @for $wh from 1 through 100 {
//   // @for $dc from 1 through 10 {
//   .right-#{$wh} {
//     right: $wh+%;
//   }

//   .left-#{$wh} {
//     left: $wh+%;
//   }

//   .top-#{$wh} {
//     top: $wh+%;
//   }

//   .bottom-#{$wh} {
//     bottom: $wh+%;
//   }

//   // }
// }

// opacity
@for $y from 0 through 100 {
    .op-#{$y} {
        opacity: "0." +$y;
    }
}

/**General/Common Styling*/
.cursor-pointer {
    cursor: pointer;
}

.display-flex {
    display: flex !important;
}

.flex-direction-column {
    flex-direction: column !important;
}

.flex-direction-row {
    flex-direction: row !important;
}

.border-none {
    border: none;
    background: transparent;
}

.position-absolute {
    position: absolute;
}

.position-relative {
    position: relative;
}

.justify-center {
    justify-content: center;
}

.justify-space-between {
    justify-content: space-between;
}

.justify-flex-start {
    justify-content: flex-start;
}

.justify-flex-end {
    justify-content: flex-end;
}

.justify-end {
    justify-content: end;
}

.align-items-flex-start {
    align-items: flex-start;
}

.align-items-center {
    align-items: center;
}

.text-align-center {
    text-align: center;
}

.text-align-left {
    text-align: left !important;
}

.text-align-right {
    text-align: right;
}

.text-align-justify {
    text-align: justify !important;
}

.flex-basis-20 {
    flex-basis: 20% !important;
}

.color-white {
    color: white !important;
}

.background-white {
    background-color: white !important;
}

.color-black {
    color: black !important;
}

.color-red {
    color: red !important;
}

.color-blue {
    color: blue !important;
}


.width-percentage-80 {
    width: 80%;
}

img {
    max-width: 100%;
}

.App {
    overflow: hidden;
}

.sr-only {
    display: none !important;
    opacity: 0;
    visibility: hidden;
}

.font-bold {
    font-weight: bold !important;
}

.section-bg {
    background: $section-bg-color;
}


/* 1.2 Global Elements */
.page-wrapper {
    position: relative;
    overflow: hidden;
}

.wow {
    visibility: hidden;
}

.fi:before {
    margin: 0;
}

.section-padding {
    background: $ligh-gray;
    padding: 0px 65px;

    .wpo-section-wrapper {
        background: $white;
        padding: 20px;

        @media(max-width: 767px) {
            padding-top: 35px !important;
        }

        @media(min-width: 768px) {
            padding-top: 50px !important;
        }
    }

    @include media-query(991px) {
        padding: 90px 0;
    }

    @include media-query(767px) {
        padding: 0px 25px;
    }
}

.custom-section-padding {
    background: $ligh-gray;
    padding: 20px 65px 0px 65px;

    .wpo-section-wrapper {
        background: $white;
        padding: 20px;
    }

    @include media-query(991px) {
        padding: 90px 0;
    }

    @include media-query(767px) {
        padding: 0px 25px;
    }
}

.event-details-padding {
    padding: 20px 225px;

    @include media-query(991px) {
        padding: 90px 0;
    }

    @include media-query(767px) {
        padding: 0px 10px;
    }
}

.pt-120 {
    padding-top: 120px;

    @include media-query(991px) {
        padding-top: 90px;
    }

    @include media-query(767px) {
        padding-top: 80px;
    }
}

.pb-120 {
    padding-bottom: 120px;

    @include media-query(991px) {
        padding-bottom: 90px;
    }

    @include media-query(767px) {
        padding-bottom: 80px;
    }
}


/*** contact form error handling ***/
.contact-validation-active {
    .error-handling-messages {
        width: 100% !important;
        margin-top: 15px !important;
    }

    label.error {
        color: red;
        font-size: calc-rem-value(14);
        font-weight: normal;
        margin: 5px 0 0 0;
        text-align: left;
        display: block;
    }

    #c-loader,
    #loader {
        display: none;
        margin-top: 10px;

        i {
            font-size: 30px;
            font-size: calc-rem-value(30);
            color: $theme-primary-color;
            display: inline-block;
            -webkit-animation: rotating linear 2s infinite;
            animation: rotating linear 2s infinite;
        }
    }

    #success,
    #c-success,
    #c-error,
    #error {
        width: 100%;
        color: $white;
        padding: 5px 10px;
        font-size: 16px;
        text-align: center;
        display: none;

        @include media-query(767px) {
            font-size: 15px;
        }
    }

    #c-success,
    #success {
        background-color: lighten(green, 5%);
        border-left: 5px solid green;
        margin-bottom: 5px;
    }

    #c-error,
    #error {
        background-color: lighten(red, 5%);
        border-left: 5px solid red;
    }
}

@-webkit-keyframes rotating {
    from {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes rotating {
    from {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.rotating {
    -webkit-animation: rotating 5s alternate infinite;
    animation: rotating 5s alternate infinite;
}


/*** back to top **/
.back-to-top {
    background-color: transparentize($theme-primary-color, 0.3);
    width: 45px;
    height: 45px;
    line-height: 45px;
    border: 2px solid $theme-primary-color;
    border-radius: 45px;
    text-align: center;
    display: none;
    position: fixed;
    z-index: 999;
    right: 15px;
    bottom: 15px;
    @include transition-time(0.2s);

    @include media-query(991px) {
        width: 35px;
        height: 35px;
        line-height: 35px;
    }

    &:hover {
        background-color: $theme-primary-color;
    }
}

.back-to-top i {
    font-size: 18px;
    font-size: calc-rem-value(18);
    color: #fff;
    position: absolute;
    left: 50%;
    top: 50%;
    @include center-by-translating();
}


/** for popup image ***/
.mfp-wrap {
    background-color: transparentize($black, 0.1);
    z-index: 99999;
}

.mfp-with-zoom .mfp-container,
.mfp-with-zoom.mfp-bg {
    opacity: 0;
    -webkit-backface-visibility: hidden;
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
}

.mfp-with-zoom.mfp-ready .mfp-container {
    opacity: 1;
}

.mfp-with-zoom.mfp-ready.mfp-bg {
    opacity: 0.8;
}

.mfp-with-zoom.mfp-removing .mfp-container,
.mfp-with-zoom.mfp-removing.mfp-bg {
    opacity: 0;
}

/*** for fancybox video ***/
.fancybox-overlay {
    background: rgba(0, 0, 0, 0.9);
    z-index: 9999 !important;
}

.fancybox-wrap {
    z-index: 99999 !important;
}




// datepicker-design


.ui-widget.ui-widget-content {
    box-shadow: 0px 1px 13.5px 1.5px rgba(46, 49, 108, 0.08);
    border: none;
    padding: 10px;
}

.ui-state-default,
.ui-widget-content .ui-state-default,
.ui-widget-header .ui-state-default,
.ui-button,
html .ui-button.ui-state-disabled:hover,
html .ui-button.ui-state-disabled:active {
    border: 1px solid #e3e3e3;
    background: #f9f9f9;
    font-weight: normal;
    color: #454545;
    text-align: center;
}

.ui-state-highlight,
.ui-widget-content .ui-state-highlight,
.ui-widget-header .ui-state-highlight {
    border: 1px solid #e3d9d9;
    background: #7f91a6;
    color: #ffffff;
}

.ui-state-active,
.ui-widget-content .ui-state-active,
.ui-widget-header .ui-state-active,
a.ui-button:active,
.ui-button:active,
.ui-button.ui-state-active:hover {
    border: 1px solid $theme-primary-color;
    background: $theme-primary-color;
    font-weight: normal;
    color: #ffffff;
}

// datepicker design

.react-datepicker__header {
    background-color: $dark-gray;
    border-bottom: 1px solid $dark-gray;
    padding: 15px 0;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
    color: $white;
}

.react-datepicker__day-name {
    color: $white;
}

.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before,
.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after {
    border-bottom-color: $dark-gray;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
    width: 1.8rem;
    line-height: 1.8rem;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
    background-color: $dark-gray;
}

.map-container {
    width: 95%;
    height: 100%;
}