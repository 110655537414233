/*--------------------------------------------------------------
4. wpo-footer
--------------------------------------------------------------*/
.wpo-site-footer {
    // background: $dark-gray;
    // background: url(../../images/footer.png) no-repeat center top / cover;
    background-image: url(../../images/footer.png);
    position: relative;
    font-size: 15px;
    overflow: hidden;
    padding: 15px;
    background-size: contain;

    @media (max-width: 768px) {
        .hide-on-mobile {
            display: none;
        }

        background-size: cover;
        background-repeat: no-repeat;
    }

    ul {
        list-style: none;
    }

    .quick-links ul {
        list-style: disc;
        padding: 0px 20px;

        display: flex;
        flex-wrap: wrap;
        /* Splits into two rows */
        justify-content: center;
        /* Centers items */
        list-style: none;
        /* Removes bullets */
        padding: 0;
        margin: 0;

        li {
            flex: 1 1 50%;
            /* Each item takes 50% width */
            text-align: left;
            padding: 5px;
            position: relative;
            /* Needed for the arrow */

            ::before {
                content: "›";
                /* Arrow symbol */
                color: black;
                font-size: 16px;
                margin-right: 8px;
                font-weight: bold;
            }

            a {
                // color: $white;
                color: $theme-secondary-color;
            }
        }
    }

    p {
        // color: $white;
        color: $black;
    }

    li {
        // color: $white;
        color: $black;
    }

    .container {
        position: relative;
    }

    .wpo-upper-footer {
        padding: 35px 0;
    }

    @media (max-width: 991px) {

        .wpo-upper-footer {
            padding: 60px 0 0;
        }
    }

    @media (max-width: 767px) {

        .wpo-upper-footer {
            padding: 60px 0 0;
        }
    }

    @media (max-width: 991px) {

        .wpo-upper-footer .col {
            min-height: 235px;
            margin-bottom: 70px;
        }
    }

    @media (max-width: 767px) {

        .wpo-upper-footer .col {
            min-height: auto;
            margin-bottom: 60px;
        }
    }

    .widget-title {
        margin-bottom: 15px;
    }

    @media (max-width: 767px) {

        .widget-title {
            margin-bottom: 20px;
        }
    }


    .widget-title {
        h3 {
            font-size: 20px;
            // color: $white;
            color: $black;
            margin: 0;
            text-transform: uppercase;
            position: relative;
            font-family: $heading-font-sub;

            @media (max-width: 991px) {
                font-size: 20px;
                font-size: 1.25rem;
            }
        }
    }

    .about-widget {
        text-align: center;

        .logo {
            // max-width: 180px;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        ul {
            display: flex;
            justify-content: center;
        }
    }

    .about-widget {
        p {
            margin-bottom: 0.8em;
            line-height: 1.9em;

            &:last-child {
                margin-bottom: 0;
            }
        }

        ul {
            overflow: hidden;
            padding-top: 10px;

            li {
                font-size: 22px;
                float: left;

                a {
                    // color: $white;
                    color: $black;
                    width: 36px;
                    height: 36px;
                    line-height: 40px;
                    background: rgba($color: $white, $alpha: 0.1);
                    display: block;
                    text-align: center;
                    @include rounded-border(50%);
                    font-size: 18px;

                    &:hover {
                        color: $dark-gray;
                        background: $white;
                    }
                }
            }

            li+li {
                margin-left: 25px;
            }
        }
    }

    .wpo-service-link-widget {
        @media screen and (min-width: 1200px) {
            padding-left: 35px;
        }

    }

    .link-widget {
        overflow: hidden;


        @media screen and (min-width: 1200px) {
            padding-left: 75px;

            &.s1 {
                padding-left: 95px;
            }
        }

        @media (max-width: 1199px) {
            padding-left: 20px;
        }

        @media (max-width: 991px) {
            padding-left: 0;
        }

        @media (max-width: 767px) {
            max-width: 350px;
        }

        ul {
            li {
                position: relative;

                a {
                    // color: $white;
                    color: $black;
                    font-size: 17px;

                    &:hover {
                        text-decoration: underline;
                    }
                }
            }

            li+li {
                padding-top: 15px;
            }
        }
    }


    .contact-ft {
        margin-top: 20px;

        ul {
            li {
                padding-bottom: 5px;
                position: relative;
                padding-left: 35px;
                // color: #e5e3e3;
                color: $black;
                font-size: 17px;

                i {
                    position: absolute;
                    left: 0;
                    top: 0;
                }

                .fi:before {
                    font-size: 20px;
                    margin-right: 15px;
                }
            }
        }
    }



    .wpo-lower-footer {
        text-align: center;
        position: relative;
        // background: #1e2332;

        .row {
            padding: 0px 0;
            position: relative;
        }

        .copyright {
            display: inline-block;
            font-size: 15px;
            font-size: 0.9375rem;
            margin: 0;
            color: $black;

            a {
                // color: $white;
                color: $black !important;
                text-decoration: none;
            }

            @media (max-width: 991px) {
                float: none;
                display: block;
            }
        }
    }

}